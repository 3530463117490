<template>
    <div class="px-4 mt-6"> 
        <div class="flex items-center justify-between">
          <h1 class="text-2xl font-medium mb-4">{{ title }}</h1>
          <vs-button type="filled" @click="addManager">Add Manager</vs-button>
        </div>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
              ref="table"
              :sst="true"
              :total="totalDocs"
              :data="data"
              :max-items="dataTableParams.limit"
              :noDataText="noDataText"
              @change-page="handleChangePage"
          >
            <div slot="header" class="flex items-center justify-between mb-4">
                  <vs-select
                      placeholder="10"
                      vs-multiple
                      autocomplete
                      v-model="dataTableParams.limit"
                      class="mr-6"
                  >
                  <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item,index) in limitOptions"
                      :clearable="false"
                  />
                  </vs-select>
              </div>

              <template slot="thead">
                  <vs-th>Account Manager Name</vs-th>
                  <vs-th>is KAM</vs-th>
                  <vs-th>Phone</vs-th>
                  <vs-th>Email</vs-th>
                  <vs-th>Timestamp</vs-th>
                  <vs-th>is Deleted</vs-th>
                  <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" class="self-center">
                      <vs-td>
                        {{ data[indextr].name }} 
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].isKAM ? 'Yes' : 'No' }} 
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].phone }} 
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].email }} 
                      </vs-td>
                      <vs-td>
                        {{ formattedDate(data[indextr].createdAt) }}
                      </vs-td>
                      <vs-td>
                        {{ data[indextr].isDeleted ? 'Yes' : 'No' }} 
                      </vs-td>
                      <vs-td>
                        <div v-if="!data[indextr].isDeleted" class="flex gap-5">
                          <vx-tooltip text="Delete" >
                            <vs-button
                              size="medium"
                              @click="deleteAccountManager(data[indextr]._id)"
                              icon-pack="feather"
                              icon="icon-trash"
                              color="danger"
                              class="w-full"
                            /> 
                          </vx-tooltip>
                          <vx-tooltip text="Edit">
                            <vs-button
                              size="medium"
                              @click="managerDetails(data[indextr]._id)"
                              icon-pack="feather"
                              icon="icon-edit"
                              color="success"
                              class="w-full"
                            />  
                          </vx-tooltip>
                        </div>
                        <vx-tooltip text="Restore" v-else>
                          <vs-button
                            size="medium"
                            @click="restoreAccountManager(data[indextr]._id)"
                            icon-pack="feather"
                            icon="icon-check"
                            color="warning"
                            class="w-full"
                          > Restore </vs-button>
                        </vx-tooltip>
                      </vs-td>
                  </vs-tr>
              </template>
          </vs-table>
          <div v-if="displayAll" class="flex items-center justify-end">
            <pagination
              v-if="serverResponded"
              :hasPrev="hasPrev" 
              :hasNext="hasNext"
              :style="{ marginTop: '5px' }"
              @handleOnChangePage="handleChangePage" />
          </div>
          <template v-else>
            <span class="mr-2">
              {{
                  dataTableParams.page * dataTableParams.limit -
                  (dataTableParams.limit - 1)
              }}
              -
              {{
                  totalDocs - dataTableParams.page * dataTableParams.limit > 0
                    ? dataTableParams.page * dataTableParams.limit
                    : totalDocs
              }}
              of {{ totalDocs }}
            </span>

            <div v-if="serverResponded" class="pagination-div">
              <paginate 
                class="pagination" 
                :page-count="totalPage" 
                :click-handler="handleChangePage" 
                :page-range="9"
                :prevText="'<'" 
                :nextText="'>'" />
            </div>
          </template>
        </div>
    </div>
  </template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import Pagination from "../../general-component/pagination.vue";

export default {
  name: "AccountManagersList",
  components: {
    "v-select": vSelect,
    Pagination
  },
  props: [
    "data", 
    "page", 
    "title",
    "totalDocs",
    "noDataText",
    "displayAll",
    "hasNext",
    "hasPrev"
  ],
  data() {
    return {
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false
    };
  },
  methods: {
    ...mapActions("appUser", ["deleteAccountManagerDetails", "restoreAccountManagerDetails", "fetchAccountManagerDetails"]),

    async fetchList() {
     this.$emit("fetchList", this.dataTableParams);
     this.serverResponded = true;
    },
    handleChangePage(page) {
      if (typeof page === 'string' && this.displayAll) {
        let payload = page === 'next' ? payload = {
          next: true,
          id: this.data[this.data.length - 1]._id,
          ...this.dataTableParams
        } : payload = {
          prev: true,
          id: this.data[0]._id,
          ...this.dataTableParams
        }
        this.$emit("fetchList", payload);
        return;
      }

      this.dataTableParams.page = page;
      this.fetchList();
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },
    async deleteAccountManager(id) {
      this.$vs.loading();

      this.deleteAccountManagerDetails(id)
        .then(res => {
          this.$vs.notify({
            title: "Account Manager Deleted",
            text: "Account manager successfully delete.",
            color: "success",
          });
          this.$router.go(); 
        })
        .catch(err => {
          console.log(err)
          this.$vs.notify({
            title: "Account Manager Deletion Failed",
            text: "Failed to delete account manager.",
            color: "danger",
          });
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    async restoreAccountManager(id) {
      this.$vs.loading();

      this.restoreAccountManagerDetails(id)
        .then(res => {
          this.$vs.notify({
            title: "Account Manager Restored",
            text: "Account manager successfully restored.",
            color: "success",
          });
          this.$router.go(); 
        })
        .catch(err => {
          this.$vs.notify({
            title: "Account Manager Restoration Failed",
            text: "Failed to restored account manager.",
            color: "danger",
          });
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    addManager(){
      this.$router.push({ name: 'create-account-managers'})
    },
    managerDetails(managerId){
      this.$router.push({ name: 'account-managers-details', params: { managerId }})
    }
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.noDataText = "Loading...";
    this.fetchList();
  }
};
</script>